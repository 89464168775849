import { LocaleType } from '../LocaleType';
import { Language } from '../../enums';
import { EnumLocale } from '../PTBR/enum';

export const Locale: LocaleType = {
    code: Language.pt,
    page: {
        cancel: 'Cancelar',
        back:'Voltar',
        save: 'Salvar',
        navigation: {
            search:'Pesquisar nº de pedido',
        },
        orderDashboard: {
            services: 'Serviços',
            message: 'Mensagem de Erro',
            date:'Data Prometida',
            default: {
                empty: 'Não existem pedidos com estes critérios no sistema.'
            }
        },        
        managementStatus: {
            title: 'Configuração de Status Gerencial',
            warning:'Adicione um ou mais status a este campo.'
        },
        configuration: {
            newName: 'Nova Configuração',
            filterName: 'Nome do Filtro',
            description: 'Descreva a configuração',
            active: 'Ativo',
            fields: {
                accn: 'Numero da Conta',
            },
            type: {
                production: 'Produção',
                manualcalculation: 'Cálculo Manual',
                other: 'Outros'
            },
            queryBuilder:{
                and: 'E',
                or: 'OU', 
                like: 'começando com', 
                contains: 'contém', 
                notContains: 'não contém',
                rule: 'Regra', 
                ruleset:'Subgrupo',
                in: 'tem',
                notIn:'não tem',                
                warning: 'Um conjunto de regras não pode estar vazio. Adicione uma regra ou remova-a todas juntas.'

            }

        },
        mainConfiguration: {
            emptyRuleList: 'Não foram encontradas configurações',
            clear: 'Limpar filtros',
            typeName: 'Tipo',
            statusName: 'Estado',
            search: 'Pesquisar nome do filtro',
            fields: {
                type: {
                    option1: "Todos",
                    option2: "Produção",
                    option3: "Cálculo Manual",
                    option4: "Outros",
                },
                state: {
                    option1: "Todos",
                    option2: "Ativo",
                    option3: "Inativo",
                },
            },
            filterTitle:"Configurar Filtros"
        },
        celula:{
            associated:'Itens Associated',
            releasedSuccess:'Liberados para Produção',
            buttonassociated: 'Atualizar filtros'
        },
        laboratorio:{
            title:'Associação de Filtros por Célula de Produção ',
            refreshIn: 'Atualizado em',
            concludedToday: 'Pedidos Concluídos Hoje',
            pending: 'Pedidos Pendentes',
            brokenPerc: 'Percentual de Quebra',
            inProduction: 'Em Produção',
            viewOrders: 'Visualizar Pedidos',
            associateFilter:'Associar Filtros',
            exceptionCell:'Exceção'
        },
        visualizarPedidos:{
            aba1: 'Pedidos Pendentes',
            aba2: 'Em Produção',
            aba3: 'Pedidos Concluidos',
            aba4: 'Percentual de Quebra',
            filter:'Pesquisar nº do pedido',
            emptyorderlist: 'Não foram encontrados pedidos para o status selecionado'
        },
        status:{
            concluded:'Concluido',
            progress: 'Andamento',
            waiting: 'Aguardando armação',
            canceled: 'Cancelado',
        },
        menu:{
            home:'Início',
            dashboard:'Dashboard',
            producao:'Produção',
            pedidos:'Pedidos',
            integracao:'Erros de Integração',
            calculo:'Erros de Cálculo',
            analise:'Pendente de Análise',
            certificado:'Erro de Certificado',
            configuracao:'Configurações',
            filtros:'Filtros',
            gerencial:'Status Gerencial',
            imprimirCertificado:'Certificado',
            releaseOrder:'Liberar Pedido',
            logout: 'Sair',
            relatorios: 'Relatórios',
            quebras: 'Relatório de Quebras',
            extracoes:'Extrações',
        },
        modalText: {
            Confirmed: {
                body: 'Deseja salvar suas alterações?',
                textButtonConfirmed: 'Confirmar',
                textButtonRefused: 'Cancelar'
            },
            YesNo: {
                body: 'Deseja realmente abandonar a operação? ',
                textButtonConfirmed: 'Sim',
                textButtonRefused: 'Não'
            },
            Deleted: {
                body: 'Deseja realmente excluir',
                textButtonConfirmed: 'Sim',
                textButtonRefused: 'Não',
            },
            producao:{
                title:'Liberar Pedidos Para Impressão',
                input:'Quantos Pedidos Deseja Liberar',
                errorPrintedOrders: 'Pedidos com erro na liberação:',
                successPrintedOrders: 'Pedidos liberados com sucesso:',
                forcedReleasedOrder: 'Pedidos já impressos liberados com sucesso:',
                noOrderSuccessfullyReleased: 'Nenhum pedido foi liberado com sucesso.',
                noOrderErrorReleased: 'Nenhum pedido teve erro de liberação.',
                noForcedReleasedOrder: 'Nenhum pedido já impresso para ser liberado.',
                close: 'Fechar',
                result: 'Resultado',
            },
            Certificado:{
                title:'Deseja realmente solicitar a reimpressão do certificado?'
            },
            feasibility:{
                leftEye: 'LENTE ESQUERDA',
                rightEye: 'LENTE DIREITA',
            },          
        },
        Request:{
            right:'Direita',
            left:'Esquerda',
            both:'Ambos',
        },
        PipeType: {
            production: 'Produção',
            calculation: 'Calculo Manual',
            others: 'Outros',
        },
        PipeActived: {
            true: 'Ativo',
            false: 'Inativo',
        },
        toastsText: {
            saveConfirmation: 'Item salvo com sucesso.',
            saveError: 'Erro ao salvar o item. Tente novamente. ',
            deletedSuccess: 'Item deletado com sucesso',
            deletedError: 'Um erro ocorreu ao deletar o item. Tente novamente.',
            liberar:'Quantidade de pedidos Liberados',
            errorPedido:'Erro ao liberar pedidos'
        },
        requestTracking:{
            title: 'Tracking de Pedidos',
            order: 'Pedido',
            eyes: 'Olhos',
            observations: 'Observações',
            promisedDate: 'Data Prometida',
            remaining:'Total restante',
            executed:'Total do pedido',

        },
        dashboard:{
            serviceRate: 'Service Rate por Status',
            volumeByHour: 'Volume de lentes hora a hora',
            startDate: 'Início',
            endDate: 'Fim',
            noReworkData: 'Não existem dados de retrabalho hoje',
            noAboveAverateData: 'Não existem dados para status acima da média de tempo',
            noBreaksData:'Não existem dados de quebra hoje',
            wipSector: 'WIP por setor',
            wipNoSector: 'WIP sem setor',
            wipMngtStatusNameStatus: 'WIP por status gerencial',
            dailyProductionByHour: 'Produção diária hora a hora',
        },
        reports:{
            startDate: 'Início',
            endDate: 'Fim',
            breakReport: 'Relatório de Quebras'
        }
    },
    enum: EnumLocale
}