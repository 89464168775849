import { LocaleType } from '../LocaleType';
import { EnumLocale } from '../ENUS/enum';
import { Language } from '../../enums';

export const Locale: LocaleType = {
    code: Language.en,
    page: {
        cancel: 'Cancel',
        back:'Back',
        save: 'Save',
        navigation: {
            search:'Search order number',
        },
        orderDashboard: {
            services: 'Services',
            message: 'Error Message',
            date:'Promised Date',

            default: {
                empty: 'There are no orders with these criteria in the system.'
            }
        },
        managementStatus: {
            title: 'Managerial Status Configuration',
            warning:'Add one or more statuses to this field.'

        },
        configuration: {
            newName: 'New Configuration',
            filterName: 'Filter Name',
            description: 'Describe the configuration',
            active: 'Active',
            fields: {
                accn: 'Account Number',
            },
            type: {
                production: 'Production',
                manualcalculation: 'Manual Calculation',
                other: 'Other'
            },
            queryBuilder: {
                and: 'AND',
                or: 'OR',
                like: 'like',
                contains: 'contains',
                notContains: 'not contains',
                rule: 'Rule',
                ruleset: 'Ruleset',
                in: 'in',
                notIn: 'not in',
                warning: 'A ruleset cannot be empty. Please add a rule or remove it all together.'
            }
        },
        mainConfiguration: {
            emptyRuleList: 'Cannot find any configuration',
            clear: 'Clean filter',
            typeName: 'Type',
            statusName: 'Status',
            search: 'Search filter name',
            fields: {
                type: {
                    option1: "All",
                    option2: "Production",
                    option3: "Manual Calculation",
                    option4: "Others",
                },
                state: {
                    option1: "All",
                    option2: "Active",
                    option3: "Inactive",
                },
            },
            filterTitle: "Set up Filter"
        },
        celula:{
            associated:'Associated Items',
            releasedSuccess:'Released to Production',
            buttonassociated: 'Update Filters'

        },
        laboratorio:{
            title:'Production Cell Filter Association',
            refreshIn: 'Updated in',
            concludedToday: 'Concluded Today',
            pending: 'Pending Orders',
            brokenPerc: 'Broken Percentage',
            inProduction: 'In Production',
            viewOrders: 'View  Orders',
            associateFilter:'Associate Filters',
            exceptionCell:'Exception'
        },
        visualizarPedidos:{
            aba1: 'Pending Orders',
            aba2: 'In Production',
            aba3: 'Orders Completed',
            aba4: 'Break Percentage',
            filter:'Search nº Orders',
            emptyorderlist: 'No orders found for selected status'

        },
        status:{
            concluded:'concluded',
            progress: 'progress',
            waiting: 'waiting',
            canceled: 'canceled',
        },
        menu:{
            home: 'Home',
            dashboard:'Dashboard',
            producao:'Production',
            pedidos:'Orders',
            integracao:'Integration Errors',
            calculo:'Calculation Errors',
            analise:'Pending Analysis',
            certificado:'Certificate Error',
            configuracao:'Configuration',
            filtros:'Filters',
            gerencial:'Managerial Status',
            imprimirCertificado:'Certificate',
            releaseOrder:'Release Order',
            logout: 'Logout',
            relatorios: 'Reports',
            quebras: 'Breaks Report',
            extracoes:'Extractions',
        },
        modalText: {
            Confirmed: {
                body: 'Do you want to save your changes?',
                textButtonConfirmed: 'Confirm',
                textButtonRefused: 'Cancel'
            },
            YesNo: {
                body: 'Do you really want to abandon the operation?',
                textButtonConfirmed: 'Yes',
                textButtonRefused: 'No'
            },
            Deleted: {
                body: 'Do you really want to delete this item?',
                textButtonConfirmed: 'Yes',
                textButtonRefused: 'No'
            },
            producao:{
                title:'Release Printable Orders',
                input:'How many orders would you like to release',
                errorPrintedOrders: 'Orders with release errors:',
                successPrintedOrders: 'Orders successfully released:',
                forcedReleasedOrder: 'Already printed orders successfully released:',
                noOrderSuccessfullyReleased: 'No order successfully released.',
                noOrderErrorReleased: 'No order with release error.',
                noForcedReleasedOrder: 'No already printed orders to release.',
                close: 'Close',
                result: 'Result',
            },
            Certificado:{
                title: 'Do you really want to request a reprint of the certificate?'
            },
            feasibility:{
                leftEye: 'LEFT LENS',
                rightEye: 'RIGHT LENS',
            },    
        },
        Request:{
            right:'Right',
            left:'Left',
            both:'Both',
        },
        PipeType: {
            production: 'Production',
            calculation: 'Manual Calculation',
            others: 'Others',
        },
        PipeActived: {
            true: 'Active',
            false: 'Inactive',
        },
        toastsText: {
            saveConfirmation: 'Item successfully saved.',
            saveError: ' Error saving item. Try again.',
            deletedSuccess: 'Item deleted successfully.',
            deletedError: 'An error occurred while deleting the item. Try again later.',
            liberar:'Order Quantity Released',
            errorPedido:'errorPedido'
        },
        requestTracking:{
            title: 'Order Tracking',
            order: 'Order',
            eyes: 'Eyes',
            observations: 'Observations',
            promisedDate: 'Promised date',
            remaining:'Remaining total',
            executed:'Executed total',
        },
        dashboard:{
            serviceRate: 'Service Rate by Status',
            volumeByHour: 'Hourly Lens Volume',
            startDate: 'Start',
            endDate: 'End',
            noReworkData: 'No rework data today',
            noAboveAverateData: 'No above average data',
            noBreaksData:'No break data today',
            wipSector: 'WIP by sector',
            wipNoSector: 'WIP with no sector',
            wipMngtStatusNameStatus: 'WIP by management status',
            dailyProductionByHour: 'Hourly Daily Production',
        },
        reports:{
            startDate: 'Start Date',
            endDate: 'End Date',
            breakReport: 'Break Report'
        }
    },
    enum: EnumLocale
}